div.nav_container {
  z-index: 15;
  width: 100%;
  position: fixed;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav_container nav {
  z-index: 20;
  display: flex;
  max-width: 1440px;
  margin-inline: auto;
  padding: var(--pd-base);
  font-family: var(--ff-accent);
  justify-content: space-between;
}
.util_menu {
  z-index: 25;
}
.nav_container nav .logo {
  z-index: 25;
  width: 130px;
  height: 55px;
  stroke-width: 10;
  stroke: rgb(0, 0, 0);
  animation: logoOutline 3s forwards;
}

@keyframes logoOutline {
  0% {
    stroke: rgb(0, 0, 0);
  }
  60% {
    stroke: rgb(0, 0, 0);
  }
  95% {
    stroke: rgb(207, 207, 5);
  }
  100% {
    stroke: rgb(207, 207, 5);
  }
}

.nav_container ul.main_menu {
  display: flex;
  text-transform: uppercase;
  place-items: center;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  z-index: 20;
}

.nav_container section.util_menu {
  gap: 1em;
  display: flex;
  place-items: center;
}

.nav_container section.util_menu img,
.menu_mobile {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.basket_icon {
  position: relative;
}
.basket_icon span.qty_change {
  top: 50%;
  left: 40%;
  z-index: 5;
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  text-align: center;
  font-size: var(--fs-medium);
  transform: translate(-40%, -50%);
  color: var(--clr-yellow-hightlight);
}

.basket_icon span:nth-child(3) {
  top: 60%;
  left: 40%;
  position: absolute;
  transform: translate(-30%, -50%);
}
.qty_change_animate {
  opacity: 1;
  margin-top: -11px;
  margin-left: -3px;
  transform: scale(2);
  color: var(--clr-yellow-hightlight);
  animation: scaleAndFade 1s ease-in-out;
}

@keyframes scaleAndFade {
  0% {
    transform: scale(5);
    opacity: 1;
  }
  90% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.nav_container .menu_mobile {
  display: none;
}

/*********----- animation -------******/

.nav_container li a {
  padding: 10px;
  transition: all 300ms ease-in-out;
}
.nav_container li:hover a {
  color: #ebee4d;
  background-color: var(--clr-navy-blue);
  cursor: pointer;
}
.nav_container li.active a {
  color: #ebee4d;
  background-color: var(--clr-navy-blue);
}
.nav_container li:nth-child(1) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: -150ms;
  animation-delay: -150ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.nav_container li:nth-child(2) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.nav_container li:nth-child(3) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.nav_container li:nth-child(4) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.nav_container li:nth-child(5) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 450ms;
  animation-delay: 450ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.nav_container li:nth-child(6) {
  -webkit-animation-name: animateMenu;
  animation-name: animateMenu;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* responsive  */

@media only screen and (max-width: 992px) {
  .nav_container nav .logo {
    height: 30px;
    width: 90px;
  }

  .nav_container nav .util_menu img {
    height: 22px;
    width: 22px;
  }
}
@media only screen and (max-width: 768px) {
  .nav_container nav {
    display: grid;
    width: 100dvw;
    margin-inline: auto;
    padding: 1em 1.5em;
    grid-template-areas:
      "logo        util_menu"
      "main_menu   main_menu";
  }

  .nav_container nav .logo {
    grid-area: logo;
    height: 25px;
    width: 80px;
  }
  .nav_container nav .main_menu {
    background-color: rgba(255, 255, 255, 0.5);
    grid-area: main_menu;
    flex-direction: column;
    padding-top: 2em;
    gap: 1em;
    height: calc(100dvh - 57px);
    position: absolute;
    width: 50vw;
    opacity: 0;
    display: none;
    transition: opacity 0.5s, display 0.5s;
    transform: translateX(120vw);
    -webkit-transform: translateX(120vw);
    transform-origin: right;
    margin-top: 40px;
    height: 100vh;
    height: 100dvh;
  }
  .nav_container nav .util_menu img {
    height: 20px;
    width: 20px;
  }
  .nav_container .menu_mobile {
    display: block;
  }

  .nav_container nav ul.main_menu.show {
    display: block;
    opacity: 1;
    transform: translateX(50vw);
    -webkit-transform: translateX(50vw);
    height: 100vh;
    height: 100dvh;
    background-color: rgba(3, 2, 28, 0.9);
  }
  @starting-style {
    .nav_container nav ul.main_menu.show {
      opacity: 0;
    }
  }
  .main_menu li {
    padding-block: 1em;
    text-align: center;
  }
  .main_menu li a {
    /* color: var(--clr-yellow-hightlight); */
    color: #ffffff;
    width: 100%;
  }
  .basket_icon span,
  .basket_icon span.qty_change {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 330px) {
  .nav_container nav {
    padding: 0.5em 1em;
  }

  .nav_container nav .logo {
    grid-area: logo;
    height: 20px;
    width: 60px;
  }
  .nav_container nav .main_menu {
    right: 0em;
    left: 0em;
  }
  .nav_container nav ul.show {
    margin-top: 28px;
  }
}
