figure.trend_item {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 9%,
    rgba(255, 248, 235, 1) 40%
  );
  position: relative;
  transition: all 400ms;
}
figure.trend_item:hover {
  scale: 1.05;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
figure.trend_item figcaption {
  display: flex;
}
figure.trend_item figcaption h4 {
  font-size: var(--fs-medium);
  font-family: var(--ff-accent);
  text-transform: uppercase;
  position: relative;
  padding: 0 var(--pd-base) 0 var(--pd-base-half);
  line-height: 40px;
}
figure.trend_item figcaption h4::after {
  top: 5px;
  right: 0;
  width: 5px;
  content: '';
  position: absolute;
  height: calc(100% - 10px);
  background-color: black;
}
figure.trend_item figcaption section {
  padding-left: var(--pd-base-half);
}
figure.trend_item figcaption section p {
  font-size: var(--fs-medium);
}
figure.trend_item > section {
  text-align: center;
}
figure.trend_item img {
  object-fit: contain;
}
figure.trend_item > h5 {
  right: 0;
  bottom: 0;
  position: absolute;
  color: var(--clr-white);
  font-size: var(--fs-medium);
  font-weight: var(--fw-light);
  background-color: var(--clr-black);
  padding-inline: calc(var(--pd-base) + 1em);
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 820px) {
  figure.trend_item figcaption section p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 550px) {
  figure.trend_item figcaption h4 {
    line-height: 30px;
    font-size: var(--fs-small);
    padding: 0 var(--pd-base-1) 0 var(--pd-base-half);
  }
  figure.trend_item figcaption section p {
    font-size: var(--fs-x-small);
  }
  figure.trend_item figcaption h4::after {
    top: 3px;
    height: calc(100% - 8px);
  }
  figure.trend_item > h5 {
    padding-inline: calc(var(--pd-base));
  }
}
@media screen and (max-width: 460px) {
  figure.trend_item figcaption {
    flex-direction: column;
  }
  figure.trend_item figcaption h4 {
    line-height: 20px;
    width: max-content;
    padding: 0 var(--pd-base-half) 0 var(--pd-base-half);
  }

  figure.trend_item figcaption h4::after {
    left: 5px;
    top: 100%;
    width: 100%;
    height: 4px;
  }
  figure.trend_item figcaption section {
    padding-top: 5px;
  }
  figure.trend_item > h5 {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 360px) {
  figure.trend_item figcaption h4 {
    line-height: 20px;
    width: max-content;
    font-size: var(--fs-x-small);
  }
  figure.trend_item > h5 {
    font-size: var(--fs-x-small);
  }
}
