.new_item {
  height: 250px;
  max-width: 270px;
  border-top: 1px solid black;
  border-left: 5px solid black;
  border-bottom: 1px solid black;
  transition: all 300ms ease-in-out;
  background-color: var(--clr-white);
  flex-grow: 1;
  flex-basis: 33%;
}
.new_item:hover {
  scale: 1.1;
  cursor: pointer;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
.new_item figure {
  position: relative;
}
.new_item figure img {
  top: 0;
  right: -30px;
  width: 160px;
  height: 230px;
  position: absolute;
  object-fit: contain;
}
.new_item figure section {
  padding-top: 15px;
}
.new_item figure section h4 {
  width: 170px;
  margin-bottom: 80px;
  padding-left: 5px;
  color: var(--clr-white);
  font-weight: var(--fw-light);
  background-color: var(--clr-black);
}
.new_item figure figcaption {
  max-width: 120px;
  padding-left: 5px;
  font-size: var(--fs-medium);
  font-weight: var(--fw-bold);
}
.new_item figure section p {
  padding-left: 5px;
  font-size: var(--fs-small);
}
.new_item figure section p span {
  padding-inline: 5px;
  border-left: 2px solid rgba(0, 0, 0, 1);
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 1120px) {
  .new_item {
    height: 220px;
    max-width: 250px;
  }
  .new_item figure img {
    width: 110px;
    right: -20px;
    height: 210px;
  }
  .new_item figure img {
    right: -20px;
    height: 210px;
    width: 150px;
  }
  .new_item figure section h4 {
    width: 150px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .new_item {
    height: 190px;
    max-width: 200px;
  }
  .new_item figure img {
    height: 200px;
    width: 120px;
  }
  .new_item figure section h4 {
    width: 130px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 620px) {
  .new_item {
    height: 140px;
    max-width: 190px;
  }
  .new_item figure img {
    height: 160px;
    width: 90px;
  }
  .new_item figure section h4 {
    width: 120px;
    margin-bottom: 25px;
    font-size: var(--fs-medium);
  }
  .new_item figure section p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 480px) {
  .new_item {
    height: 140px;
    max-width: 140px;
  }
  .new_item figure img {
    height: 140px;
    width: 80px;
  }
  .new_item figure section h4 {
    width: 80px;
    margin-bottom: 25px;
    font-size: var(--fs-small);
  }
  .new_item figure section p {
    font-size: var(--fs-x-small);
  }
}

@media screen and (max-width: 400px) {
  .new_item {
    height: 100px;
    max-width: 100px;
  }
  .new_item figure img {
    right: -10px;
    height: 70px;
    width: 50px;
  }
  .new_item figure section h4 {
    width: 50px;
    margin-bottom: 15px;
    font-size: var(--fs-xx-small);
  }
  .new_item figure section p {
    font-size: var(--fs-xx-small);
  }
  .new_item figure section figcaption {
    font-size: var(--fs-xx-small);
  }
}
