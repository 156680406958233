div.product_cart_container {
  gap: 1.5em;
  display: flex;
  flex-wrap: wrap;
  min-height: 80vh;
  margin-inline: auto;
  transition: all 0.5s;
  padding: var(--pd-base);
  max-width: var(--wd-base);
  justify-content: space-between;
}
div.product_cart_container > * {
  flex-grow: 1;
}
section.btn_container {
  min-width: 100%;
  text-align: center;
  padding: var(--pd-base);
}
section.btn_container button {
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1em 2em;
  color: var(--clr-white);
  font-weight: var(--fw-light);
  transition: all 300ms;
  background-color: var(--clr-navy-blue);
}
section.btn_container button:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */
@media screen and (max-width: 998px) {
  section.btn_container button {
    font-size: 0.875rem;
    padding: 0.8em 1em;
    font-size: var(--fs-medium);
  }
}
@media screen and (max-width: 580px) {
  div.product_cart_container {
    gap: 1em;
  }
  section.btn_container button {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 440px) {
  div.product_cart_container {
    gap: 0.5em;
  }
  section.btn_container button {
    font-size: var(--fs-x-small);
  }
}
