.basket_item {
  display: grid;
  text-align: center;
  margin-block: 20px;
  padding-block: 20px;
  box-shadow: var(--bxsh-df-shadow);
  grid-template-columns: repeat(5, 1fr);
  background-color: rgba(255, 255, 255, 1);
}
.basket_item section {
  display: grid;
  place-items: center;
}
.item_description {
  position: relative;
}
.item_description h4 {
  left: -10px;
  bottom: 25px;
  max-width: 120px;
  text-align: left;
  position: absolute;
  transform: rotate(-90deg);
  font-size: var(--fs-small);
}
.item_description img {
  width: 70px;
  height: 70px;
  object-fit: contain;
}
.item_size p {
  padding: 4px 10px;
  font-size: var(--fs-small);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.basket_item section.item_quantity {
  position: relative;
}
.basket_item section.item_quantity div {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.basket_item section.item_quantity div p {
  line-height: 35px;
  font-size: var(--fs-medium);
}
.item_quantity div button,
.item_delete {
  width: 35px;
  height: 35px;
  border: none;
  margin-inline: auto;
}
.item_quantity div button:nth-child(1) {
  color: #ffffff;
  background-color: var(--clr-navy-blue);
}
.item_quantity div button:hover,
.item_delete:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.item_quantity div button:nth-child(1):hover {
  background-color: var(--clr-dark-navy-blue);
}
.stock_warning {
  bottom: -5px;
  color: red;
  width: 200px;
  position: absolute;
  font-size: var(--fs-x-small);
}
.item_price p {
  font-size: var(--fs-small);
}
.item_price div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.item_price div p:nth-child(2) {
  padding-inline: 2px;
  background-color: var(--clr-red-hightlight);
}
.item_price div p:nth-child(3) {
  grid-column: span 2;
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1150px) {
  .basket_item {
    margin-block: 10px;
    padding-block: 15px;
    grid-template-columns: repeat(5, 1fr);
  }
  .item_description h4 {
    left: -20px;
    max-width: 100px;
  }
  .item_description img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
  }
  .item_size p {
    padding: 2px 6px;
    font-size: var(--fs-x-small);
  }
  .basket_item section.item_quantity div p {
    line-height: 25px;
    font-size: var(--fs-small);
  }
  .item_quantity div button,
  .item_delete {
    width: 25px;
    height: 25px;
    border: none;
    margin-inline: auto;
  }
  .item_delete {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 992px) {
  .item_description h4 {
    left: -26px;
    max-width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .item_description h4 {
    left: -20px;
    bottom: 15px;
    max-width: 75px;
  }
  .item_description img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }
  .item_price p {
    font-size: var(--fs-x-small);
  }
}
@media only screen and (max-width: 460px) {
  .item_description h4 {
    left: -10px;
    bottom: 5px;
    max-width: 55px;
  }
  .item_description img {
    width: 40px;
    height: 40px;
    margin-left: 35px;
  }
  .item_size p {
    padding: 2px 4px;
  }
  .basket_item section.item_quantity div p {
    line-height: 20px;
    font-size: var(--fs-x-small);
  }
  .item_quantity div button,
  .item_delete {
    width: 20px;
    height: 20px;
  }
  .item_delete {
    font-size: var(--fs-x-small);
  }
}

@media only screen and (max-width: 460px) {
  .basket_item {
    margin-block: 6px;
    padding-block: 10px;
  }
  .item_description h4 {
    left: -10px;
    bottom: 10px;
    max-width: 55px;
    font-size: var(--fs-x-small);
  }
}
