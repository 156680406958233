.payment {
  margin-inline: auto;
  max-width: var(--wd-base);
  display: grid;
  justify-content: center;
  gap: 1.5rem;
  padding: var(--pd-base);
  min-height: 88vh;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  background: rgb(255, 248, 235);
  background: radial-gradient(
    circle,
    rgba(255, 248, 235, 1) 6%,
    rgba(255, 255, 255, 1) 44%
  );
}
.payment h2 {
  margin-block: 1rem;
  font-size: var(--fs-x-large);
  font-weight: var(--fw-bold);
  text-align: center;
}
.payment > section {
  padding-bottom: 1em;
  padding-inline: calc(var(--pd-base) * 2);
  border: 1px solid rgba(162, 160, 160, 0.1);
}
.order_summery_total {
  color: #ffffff;
  text-align: center;
  background-color: black;
  padding: 8px;
  margin-top: 10px;
  font-size: var(--fs-medium);
}
.order_summery_items {
  gap: 0.5em;
  display: flex;
  margin-bottom: 1.4em;
}
.btn_basket {
  border: none;
  padding: 0.5em;
  max-height: 50px;
  font-size: 0.75rem;
  margin: 1em 0em;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-light);
  background-color: var(--clr-navy-blue);
}
.btn_basket img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  transform: rotate(-90deg);
}
.btn_basket:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1100px) {
  .payment {
    gap: 1rem;
  }
  .payment > section {
    padding-inline: var(--pd-base);
  }
}
@media only screen and (max-width: 992px) {
  .payment h2 {
    font-size: var(--fs-large);
  }
}
@media only screen and (max-width: 600px) {
  .payment h2 {
    font-size: var(--fs-large);
  }
}
@media only screen and (max-width: 400px) {
  .payment {
    grid-template-columns: 1fr;
  }
  .payment h2 {
    font-size: var(--fs-medium);
  }
}

@media only screen and (max-width: 768px) {
  .purchase_details_container {
    padding-top: 1em;
  }
  .btn_basket {
    font-size: var(--fs-medium);
  }
}
@media screen and (max-width: 580px) {
  .btn_basket {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 460px) {
  .btn_basket {
    font-size: var(--fs-x-small);
  }
  .btn_basket img {
    width: 10px;
    height: 10px;
  }
}
