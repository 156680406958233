.suggested_container {
  gap: 1em;
  display: grid;
  margin-inline: auto;
  padding: var(--pd-base);
  max-width: var(--wd-base);
  grid-template-columns: repeat(4, 1fr);
}
.suggested_container .suggested_text {
  grid-column: 1 / span 2;
}
.suggested_text h3 {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.1);
  font-weight: var(--fw-black);
  font-size: calc(var(--fs-xx-large) * 2.8);
}
.suggested_text h3:last-child {
  text-align: right;
  padding-right: 35px;
}
.suggested_text h6 {
  text-transform: uppercase;
  font-size: var(--fs-xx-large);
}
.suggested_text p {
  font-size: var(--fs-medium);
  font-weight: var(--fw-bold);
}
@media screen and (max-width: 1300px) {
  .suggested_container {
    gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    margin-inline: auto;
    padding: var(--pd-base);
    max-width: var(--wd-base);
    justify-content: space-between;
  }
  .suggested_container > * {
    flex-grow: 1;
  }
  .suggested_text h3:last-child {
    padding-right: 0px;
  }
}
@media screen and (max-width: 842px) {
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 2.6);
  }
  .suggested_text h6 {
    font-size: var(--fs-x-large);
  }
  .suggested_text p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 775px) {
  .suggested_text {
    max-width: 340px;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 2.4);
  }
  .suggested_text h6 {
    font-size: var(--fs-large);
  }
  .suggested_text p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 713px) {
  .suggested_text {
    max-width: 290px;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 2.4);
  }
  .suggested_text h6 {
    font-size: var(--fs-large);
  }
  .suggested_text p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 680px) {
  .suggested_text {
    max-width: 300px;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 2.4);
  }
  .suggested_text h6 {
    font-size: var(--fs-large);
  }
  .suggested_text p {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 623px) {
  .suggested_text {
    max-width: 100%;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 2);
  }
  .suggested_text h6 {
    font-size: var(--fs-medium);
  }
  .suggested_text p {
    font-size: var(--fs-x-small);
  }
}
@media screen and (max-width: 580px) {
  .suggested_container {
    gap: 1em;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large) * 1.4);
  }
}
@media screen and (max-width: 440px) {
  .suggested_container {
    gap: 0.5em;
  }
  .suggested_text h3 {
    font-size: calc(var(--fs-xx-large));
  }
}
@media screen and (max-width: 360px) {
  .suggested_text h3 {
    font-size: calc(var(--fs-x-large));
  }
}
