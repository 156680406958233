.checkout_form button {
  width: 100%;
  padding: 12px;
  font-size: var(--fs-medium);
  margin-top: 18px;
  border-radius: none;
  border: transparent;
  background-color: var(--clr-yellow-hightlight);
  transition: all 0.4s;
}

.checkout_form button:hover {
  cursor: pointer;
  background-color: var(--clr-yellow-hightlight2);
}
