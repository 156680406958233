.sign_up_container {
  width: 600px;
  height: 500px;
  display: grid;
  margin-top: 50px;
  position: relative;
  place-items: center;
  margin-inline: auto;
  background-size: contain;
  background-position: 0 10px;
  background-repeat: no-repeat;
  box-shadow: var(--bxsh-df-shadow);
  background-color: var(--clr-navy-blue);
  background-image: url('../../assests/login_bg_texture.png');
}
.triangle_container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.triangle_container div:nth-child(1) {
  top: 50px;
  left: -160px;
  width: 240px;
  height: 240px;
  position: absolute;
  transform: rotate(42deg);
  background-color: rgba(227, 243, 138, 0.1);
}
.triangle_container div:nth-child(2) {
  left: 50px;
  width: 390px;
  height: 350px;
  bottom: -180px;
  position: absolute;
  transform: rotate(42deg);
  background-color: rgba(227, 243, 138, 0.1);
}
.triangle_container div:nth-child(3) {
  width: 390px;
  bottom: -20px;
  right: -280px;
  height: 350px;
  position: absolute;
  transform: rotate(42deg);
  background-color: rgba(227, 243, 138, 0.1);
}
.triangle_container p {
  top: 0px;
  right: 20px;
  font-size: 25rem;
  position: absolute;
  color: #02233a;
  font-weight: var(--fw-extra-black);
}
.sign_up_form {
  z-index: 5;
}
.sign_up_input_group {
  gap: 10px;
  display: grid;
  margin-block: 10px;
  margin-bottom: 20px;
  font-size: var(--fs-medium);
  grid-template-columns: 1fr 2fr;
  font-weight: var(--fw-extra-light);
}

.sign_up_input_group input {
  border: none;
  color: #ffffff;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid #ffffff;
}
.sign_up_input_group input:focus {
  box-shadow: none;
  outline-style: none;
  border-bottom: 2px solid #ffffff;
}
.sign_up_input_group label {
  gap: 5px;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
}
.sign_up_btn_info {
  position: relative;
}
.sign_up_btn_info button {
  width: 100%;
  border: none;
  color: #ffffff;
  margin-block: 15px;
  position: relative;
  transition: all 0.4s;
  padding: var(--pd-base-1);
  border: 1px solid #ffffff;
  background-color: #141a38;
  font-family: var(--ff-base);
  font-weight: var(--fw-extra-light);
}
.sign_up_btn_info button:hover {
  cursor: pointer;
  background-color: #0e132b;
}

.signin li {
  margin-block: 15px;
}
.signin li a {
  color: #ffffff;
  transition: all 400s;
  font-size: var(--fs-small);
  font-weight: var(--fw-extra-light);
}
.err_msg {
  color: #f4690c;
}
.success_msg {
  color: #96d10e;
}
.signup_info {
  bottom: -1.8em;
  text-align: center;
  position: absolute;
  font-size: var(--fs-small);
  animation: blink 500ms linear forwards;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0.5;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 768px) {
  .sign_up_container {
    width: 450px;
    height: 500px;
  }
  .triangle_container div:nth-child(1) {
    top: 110px;
    width: 220px;
    height: 200px;
  }
  .triangle_container div:nth-child(2) {
    left: 30px;
    width: 360px;
    height: 320px;
  }
  .triangle_container div:nth-child(3) {
    bottom: 20px;
    width: 340px;
    height: 350px;
  }
  .triangle_container p {
    right: 0px;
    font-size: 19rem;
  }
  .sign_up_btn_info button {
    margin-block: 15px;
    padding: var(--pd-base-half);
  }
}

@media only screen and (max-width: 460px) {
  .sign_up_container {
    width: 350px;
    height: 400px;
  }
  .triangle_container div:nth-child(1) {
    top: 90px;
    width: 210px;
    height: 200px;
  }
  .triangle_container div:nth-child(2) {
    left: 10px;
    width: 300px;
    height: 280px;
  }
  .triangle_container div:nth-child(3) {
    width: 340px;
    height: 300px;
  }
  .sign_up_input_group {
    margin-block: 5px;
    margin-bottom: 15px;
    font-size: var(--fs-small);
  }
  .triangle_container p {
    font-size: 17rem;
  }
  .sign_up_btn_info button {
    margin-block: 15px;
    padding: var(--pd-base-half);
  }
}

@media only screen and (max-width: 360px) {
  .sign_up_container {
    width: 250px;
    height: 420px;
  }
  .triangle_container div:nth-child(1) {
    top: 120px;
    width: 180px;
    height: 200px;
  }
  .triangle_container div:nth-child(2) {
    left: -10px;
    width: 320px;
    height: 270px;
  }
  .triangle_container div:nth-child(3) {
    bottom: 50px;
    width: 340px;
    height: 300px;
  }
  .triangle_container p {
    font-size: 14rem;
  }
  .sign_up_form {
    margin-inline: 0.5em;
  }
  .signin li {
    margin-block: 0px;
    margin-top: 15px;
  }
  .sign_up_btn_info button {
    margin-block: 15px;
    padding: var(--pd-base-point2);
  }
  .signup_info {
    bottom: -1.8em;
    font-size: var(--fs-x-small);
  }
}
