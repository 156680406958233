div.filter_container {
  gap: 5em;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  margin-inline: auto;
  max-width: calc(var(--wd-base) - var(--mr-base) * 2);
  padding-inline: var(--pd-base);
  justify-content: space-between;
  padding-block: var(--pd-base-half);
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  font-size: var(--fs-medium);
}
div.filter_container > * {
  flex: 1;
}

/* price  */
section.price div p:nth-child(2) {
  color: var(--clr-red-hightlight-light);
  font-weight: var(--fw-bold);
}
.price input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
.price input[type="range"]:focus {
  outline: none;
}
.price input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #000000;
}
.price input[type="range"]::-webkit-slider-thumb {
  border: 1px solid #000000;
  height: 33px;
  width: 16px;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
.price input[type="range"]:focus::-webkit-slider-runnable-track {
  background-color: #000000;
}
.price input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #000000;
}
.price input[type="range"]::-moz-range-thumb {
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
.price input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.price input[type="range"]::-ms-fill-lower {
  background: #000000;
  border-radius: 2px;
}
.price input[type="range"]::-ms-fill-upper {
  background: #000000;
  border-radius: 2px;
}
.price input[type="range"]::-ms-thumb {
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
.price input[type="range"]:focus::-ms-fill-lower {
  background: #000000;
}
.price input[type="range"]:focus::-ms-fill-upper {
  background: #000000;
}
input.selected[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--clr-yellow-hightlight);
}
input.selected[type="range"]::-ms-track {
  background-color: var(--clr-yellow-hightlight);
}
input.selected[type="range"]::-moz-range-track {
  background-color: var(--clr-yellow-hightlight);
}
.price div {
  display: flex;
  justify-content: space-between;
}
/* size */
section.size {
  padding-inline: 1em;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}
section.size div {
  max-width: max-content;
  margin-inline: auto;
}
section.size ul {
  margin-top: 10px;
  display: flex;
  gap: 0.5em;
}
section.size ul li {
  width: 50px;
  text-align: center;
  border: 1px solid black;
  transition: all 300ms ease-in-out;
}
section.size ul li:hover {
  cursor: pointer;
  background-color: var(--clr-yellow-hightlight);
}
.active {
  background-color: var(--clr-yellow-hightlight);
}
/* title */
.title p {
  padding-bottom: 0.7em;
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 1450px) {
  /* div.filter_container {
    margin-inline: var(--mr-base);
  } */
}
@media screen and (max-width: 998px) {
  div.filter_container {
    gap: 3em;
  }
}

@media screen and (max-width: 860px) {
  div.filter_container {
    gap: 1em;
  }
}
@media screen and (max-width: 560px) {
  div.filter_container {
    justify-content: space-around;
    font-size: var(--fs-small);
  }
  .price input[type="range"] {
    margin: 6px 0;
  }
  .price input[type="range"]::-webkit-slider-runnable-track {
    height: 4px;
  }
  .price input[type="range"]::-webkit-slider-thumb {
    height: 13px;
    width: 10px;
    margin-top: -5px;
  }
  .price input[type="range"]::-ms-track {
    height: 4px;
  }
  .price input[type="range"]::-moz-range-track {
    height: 4px;
  }
  .price input[type="range"]::-moz-range-thumb {
    height: 16px;
    width: 10px;
  }
  .price input[type="range"]::-ms-track {
    height: 4px;
    border-width: 12px 0;
  }
  .price input[type="range"]::-ms-thumb {
    height: 16px;
    width: 10px;
  }
  section.size {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    border-right: 0;
    padding-right: 0;
  }
  section.title {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    width: 200px;
    padding-inline: 10em;
  }
  section.title {
    font-size: var(--fs-small);
  }
}
@media screen and (max-width: 500px) {
  section.size {
    padding-inline: 0.5em;
  }
  section.size ul {
    margin-top: 5px;
    gap: 0.2em;
  }
  section.size ul li {
    width: 40px;
  }
}
@media screen and (max-width: 400px) {
  div.filter_container {
    gap: 0.5em;
  }
  div.filter_container {
    font-size: var(--fs-x-small);
  }
  section.title select {
    font-size: var(--fs-x-small);
  }
}
@media screen and (max-width: 320px) {
  section.title {
    padding-inline: 6em;
  }
}
@media screen and (max-width: 306px) {
  section.size {
    border-left: 0;
    padding-inline: 1em;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
  section.title {
    padding-inline: 3em;
  }
  section.title label {
    padding-right: var(--pd-base-half);
  }
  section.title br {
    display: none;
  }
}
