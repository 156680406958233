.about_main {
  min-height: 100vh;
  margin-inline: auto;
  padding: var(--pd-base);
  max-width: var(--wd-base);
}
.introduction {
  display: flex;
  align-items: center;
}
.initial {
  display: flex;
  position: relative;
}
.initial p.letter {
  font-size: 15em;
  letter-spacing: -0.2ch;
  font-weight: var(--fw-bold);
  color: rgba(0, 0, 0, 0.1);
}
.who_are_we {
  top: 50%;
  left: 70%;
  width: 250px;
  text-align: center;
  position: absolute;
  font-style: italic;
  font-family: var(--ff-accent);
  transform: translateY(-50%) rotate(-90deg);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.who_are_we span {
  width: 5px;
  height: 5px;
  margin-bottom: 2px;
  margin-inline: 5px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid var(--clr-yellow-hightlight2);
}
.who_are_we::before {
  top: 55%;
  content: "";
  height: 1px;
  width: 60px;
  right: -60px;
  position: absolute;
  transform: translateY(-50%);
  background-color: var(--clr-yellow-hightlight2);
}
.who_are_we::after {
  top: 55%;
  content: "";
  height: 1px;
  width: 60px;
  left: -60px;
  position: absolute;
  transform: translateY(-50%);
  background-color: var(--clr-yellow-hightlight2);
}
.description {
  margin-left: 100px;
  text-align: justify;
}
.description a.link_balhowaimil {
  color: blue;
  text-decoration: underline;
}
.about_bg {
  opacity: 0.4;
  padding: 1em;
  margin-block: 1em;
  position: relative;
  border: 5px solid var(--clr-yellow-hightlight2);
}
.about_bg p {
  top: 50%;
  left: 50%;
  color: #fff;
  font-size: 2rem;
  font-style: italic;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
}
.about_bg img {
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
}

.vision {
  text-align: justify;
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 768px) {
  .introduction {
    flex-direction: column;
  }
  .who_are_we {
    top: auto;
    left: 50%;
    bottom: -10%;
    width: 250px;
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  .description {
    margin-left: 0;
    margin-top: 1em;
  }

  .initial p.letter {
    line-height: 220px;
    font-size: 15em;
    letter-spacing: -0.2ch;
    font-weight: var(--fw-bold);
    color: rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 460px) {
  .initial p.letter {
    line-height: 190px;
    font-size: 12em;
  }
  .description,
  .vision p {
    font-size: var(--fs-medium);
  }
  .about_bg p {
    font-size: var(--fs-large);
  }
}
