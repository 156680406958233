.custom_dropdown {
  position: relative;
  width: 100%; /* Make it responsive by using percentage-based width */
  max-width: 300px; /* Maximum width of the dropdown */
}

/* Header styling */
.dropdown_header {
  padding: 1px;
  font-size: 14px;
  border: 1px solid var(--clr-yellow-hightlight2);
  text-transform: capitalize;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in width */
}

/* Dropdown content styling */
.dropdown_content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid var(--clr-yellow-hightlight2);
  border-top: none;
  z-index: 1000;
  border-radius: 0;
  max-height: 250px;
  overflow-y: auto;
  box-sizing: border-box; /* Ensures padding is included in width/height */
}

/* Filter input styling */
.dropdown_filter {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 14px;
}

/* Dropdown list styling */
.dropdown_list {
  max-height: 200px;
  overflow-y: auto;
  text-transform: capitalize;
}

/* Dropdown item styling */
.dropdown_item {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

/* Hover effect for dropdown items */
.dropdown_item:hover {
  background-color: var(
    --clr-yellow-hightlight
  ); /* Change background color to yellow on hover */
}

/* Selected option styling */
.dropdown_item_selected {
  background-color: var(--clr-yellow-hightlight2);
}

/* No results message styling */
.no_results {
  padding: 10px;
  color: #999;
  font-style: italic;
  cursor: default;
}

/* Arrow styling */
.arrow {
  color: var(--clr-yellow-hightlight2);
}

/* Media Queries for Responsiveness */

/* For medium screens (tablets, etc.) */
@media (max-width: 768px) {
  .dropdown_header {
    font-size: 14px;
  }

  .dropdown_list {
    max-height: 200px; /* Adjust height slightly */
  }
}
/* For smaller screens (mobile devices) */
@media (max-width: 600px) {
  .dropdown_header {
    font-size: 12px; /* Smaller font size */
  }

  .dropdown_list {
    max-height: 150px; /* Adjust max height */
  }

  .dropdown_filter {
    font-size: 12px; /* Smaller font size for the filter input */
  }

  .dropdown_item {
    padding: 4px; /* Reduced padding for smaller screens */
  }

  /* Max width for dropdown on mobile */
  .custom_dropdown {
    max-width: 100%; /* Full width on small screens */
  }
}

@media (max-width: 400px) {
  .dropdown_header {
    font-size: 10px;
  }
}
