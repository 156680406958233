.err_msg,
.success_msg {
  margin: auto;
  width: 300px;
  padding: 2em;
  text-align: center;
  font-size: var(--fs-normal);
}
.err_msg {
  background: rgb(236, 128, 128);
}
.success_msg {
  background: rgb(121, 213, 113);
}

@media only screen and (max-width: 560px) {
  .err_msg,
  .success_msg {
    font-size: var(--fs-medium);
  }
}
@media only screen and (max-width: 400px) {
  .err_msg,
  .success_msg {
    font-size: var(--fs-small);
  }
}
