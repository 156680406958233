.cart_item {
  width: 300px;
  height: 320px;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 9%,
    rgba(255, 248, 235, 1) 40%
  );
  position: relative;
  transition: scale 400ms;
}
.link_wrapper {
  width: 100%;
  height: 100%;
}
.cart_item:hover {
  scale: 1.05;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
.cart_item img {
  top: 15px;
  left: 50%;
  width: 140px;
  height: 250px;
  position: absolute;
  object-fit: contain;
  transform: translateX(-50%);
}
.cart_item figcaption {
  bottom: 0;
  display: flex;
  position: absolute;
}
.cart_item figcaption h4 {
  width: 90px;
  position: relative;
  text-transform: uppercase;
  font-size: var(--fs-normal);
  padding: var(--pd-base-half);
  font-family: var(--ff-accent);
}
.cart_item figcaption section {
  position: relative;
}
.cart_item figcaption section p {
  font-size: var(--fs-small);
}
.cart_item figcaption section {
  position: relative;
  margin-top: 5px;
  padding-left: var(--pd-base-half);
}
.cart_item figcaption section p span:nth-child(2) {
  font-weight: var(--fw-black);
  margin: 0 2px;
}
.cart_item figcaption section::before {
  top: 3px;
  width: 3px;
  left: 0;
  content: '';
  position: absolute;
  height: calc(100% - 8px);
  background-color: black;
}
.cart_item h5 {
  right: 0;
  top: 50px;
  position: absolute;
  color: var(--clr-white);
  font-size: var(--fs-small);
  font-weight: var(--fw-extra-light);
  background-color: var(--clr-black);
  padding: var(--pd-base-half) var(--pd-base);
}
/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 680px) {
  .cart_item {
    width: 250px;
    height: 270px;
  }
  .cart_item figcaption h4 {
    width: 70px;
    font-size: var(--fs-medium);
  }
  .cart_item img {
    width: 100px;
    height: 210px;
  }
  .cart_item h5 {
    padding: var(--pd-base-half) var(--pd-base-half);
  }
}
@media screen and (max-width: 580px) {
  .cart_item {
    width: 180px;
    height: 200px;
  }
  .cart_item figcaption h4 {
    width: 60px;
    font-size: var(--fs-small);
  }
  .cart_item img {
    width: 60px;
    height: 160px;
  }
  .cart_item figcaption section p {
    font-size: var(--fs-x-small);
  }
  .cart_item h5 {
    font-size: var(--fs-x-small);
  }
}
@media screen and (max-width: 440px) {
  .cart_item {
    width: 130px;
    height: 140px;
  }
  .cart_item figcaption h4 {
    width: 60px;
    font-size: var(--fs-x-small);
  }
  .cart_item img {
    top: 0px;
    left: 35%;
    width: 40px;
    height: 120px;
  }
  .cart_item figcaption section p {
    font-size: var(--fs-x-small);
  }
  .cart_item h5 {
    top: 20px;
    font-size: var(--fs-x-small);
  }
}
@media screen and (max-width: 360px) {
  .cart_item figcaption h4 {
    width: 50px;
  }
  .cart_item h5 {
    top: 15px;
  }
}
@media screen and (max-width: 330px) {
  .cart_item {
    width: 110px;
    height: 150px;
  }
  .cart_item figcaption h4 {
    width: 45px;
    padding-inline: 0.2em;
  }
  .cart_item img {
    left: 30%;
    height: 100px;
  }
  .cart_item figcaption section {
    padding-left: 0.3em;
  }
  .cart_item figcaption section::before {
    width: 2px;
  }
  .cart_item h5 {
    top: 15px;
    padding: 0.2em;
  }
}
@media screen and (max-width: 310px) {
  .cart_item figcaption {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .cart_item figcaption h4 {
    width: 100%;
    margin-left: 0.3em;
    padding: var(--pd-base-point2);
  }
  .cart_item figcaption section {
    padding-left: 0.3em;
    margin-top: 2px;
  }
  .cart_item figcaption section::before {
    width: 0px;
  }
}
