header {
  height: 100px;
}
@media only screen and (max-width: 992px) {
  header {
    height: 78px;
  }
}

@media only screen and (max-width: 768px) {
  header {
    height: 57px;
  }
}
@media only screen and (max-width: 330px) {
  header {
    height: 36px;
  }
}
