.tc_container {
  min-height: 100vh;
  display: grid;
  overflow-y: auto;
  margin-top: 50px;
  max-width: 1200px;
  margin-bottom: 50px;
  padding-bottom: 30px;
  padding-inline: 20px;
  text-align: center;
  position: relative;
  place-items: center;
  margin-inline: auto;
  background-size: contain;
  background-position: 0 10px;
  background-repeat: no-repeat;
  box-shadow: 0 20px 30px 0 rgba(143, 141, 141, 0.1),
    0 2px 2px 0 rgba(139, 139, 139, 0.01);
  background-image: url("../../assests/login_bg_texture.png");
  background-repeat: repeat-y;
  background-position: -20px;
}
.tc_container p {
  text-align: justify;
}

/* ************* **********  ****************
  **************** responsive  ****************
  **************** **********  ************* */

@media only screen and (max-width: 768px) {
  .tc_container {
    width: 450px;
    font-size: var(--fs-medium);
  }
}

@media only screen and (max-width: 460px) {
  .tc_container {
    width: 350px;
  }
}

@media only screen and (max-width: 360px) {
  .tc_container {
    width: 250px;
  }
}
