.order_success_container {
  display: grid;
  min-height: 60vh;
  place-items: center;
}
.order_success_container section {
  text-align: center;
  padding: calc(var(--pd-base) * 2);
  background-color: var(--clr-base);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1),
    0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
.order_success_container section h1 {
  padding: var(--pd-base-point2);
  font-size: var(--fs-x-large);
}
.order_success_container section button {
  padding: var(--pd-base-1);
}
.order_success_container section button {
  border: none;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 1em 2em;
  background-color: var(--clr-navy-blue);
  color: var(--clr-white);
  font-weight: var(--fw-light);
  margin-top: 2em;
  transition: all 300ms;
}
.order_success_container section button:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
