.address_payment {
  display: flex;
  color: #ffffff;
  flex-direction: column;
  padding: var(--pd-base-1);
  margin-bottom: var(--mr-base);
  justify-content: space-between;
  background-color: var(--clr-navy-blue);
  max-height: 700px;
}

.address_payment h3 {
  text-align: center;
  font-size: var(--fs-large);
  border-bottom: 1px solid #ffffff;
  font-weight: var(--fw-extra-light);
}
.address_input_group {
  gap: 10px;
  display: grid;
  margin-block: 10px;
  font-size: var(--fs-small);
  grid-template-columns: 1fr 2fr;
  font-weight: var(--fw-extra-light);
}
.address_input_group input {
  border: none;
  color: #ffffff;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid #ffffff;
}
.address_input_group input:focus {
  box-shadow: none;
  outline-style: none;
  border-bottom: 2px solid #ffffff;
}
.address_input_group label {
  display: flex;
  justify-content: space-between;
}

.address_payment_info h4,
.address_payment_info p {
  display: flex;
  font-weight: var(--fw-extra-light);
  justify-content: space-between;
}
.payment_info {
  margin-bottom: 50px;
}
.address_payment button {
  width: 100%;
  border: none;
  padding: var(--pd-base-1);
  transition: all 0.4s;
  background-color: var(--clr-yellow-hightlight);
  margin: calc(var(--mr-base) * 4) 0 calc(var(--mr-base) * 2);
}
.address_payment button:hover {
  cursor: pointer;
  background-color: var(--clr-yellow-hightlight2);
}
.delivery_chrage_condition {
  font-size: var(--fs-x-small);
}
.payment_total {
  margin-top: 20px;
  border-top: 1px solid #ffffff;
}
.slideIn {
  position: relative;
  display: inline-block;
  animation: slideInFromBottom 1s ease-out forwards;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.warning {
  max-width: 300px;
  color: #f4be0c;
  text-align: center;
  max-width: max-content;
  margin-inline: auto;
  font-size: var(--fs-medium);
  animation: blink 500ms linear forwards;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0.5;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1150px) {
  .address_payment h3 {
    font-size: var(--fs-medium);
  }
  .address_payment_info h4,
  .address_payment_info p {
    font-size: var(--fs-medium);
  }
  .address_payment_info p.delivery_chrage_condition {
    font-size: var(--fs-x-small);
  }
}

@media only screen and (max-width: 992px) {
  .address_payment button {
    padding: var(--pd-base-half);
  }
}
@media only screen and (max-width: 768px) {
  .address_payment {
    margin-inline: var(--pd-base-1);
  }
  .payment_info {
    margin-bottom: 35px;
  }
  .address_input_group {
    gap: 8px;
    font-size: var(--fs-small);
  }
  .address_input_group input {
    font-size: var(--fs-small);
  }
  .address_payment button {
    margin: calc(var(--mr-base) * 2) 0 calc(var(--mr-base));
  }
}
@media only screen and (max-width: 400px) {
  .address_payment {
    margin-inline: var(--pd-base-1);
  }
  .address_payment h3 {
    font-size: var(--fs-small);
  }
  .address_payment_info h4,
  .address_payment_info p {
    font-size: var(--fs-small);
  }
  .address_input_group {
    gap: 5px;
    font-size: var(--fs-x-small);
  }
  .address_input_group input {
    font-size: var(--fs-x-small);
  }
  .payment_info {
    margin-bottom: 25px;
  }
  .address_payment button {
    margin: calc(var(--mr-base)) 0 calc(var(--mr-base));
  }
  .warning {
    font-size: var(--fs-x-small);
  }
}
