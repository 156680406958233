div.hero {
  min-height: 860px;
  position: relative;
  padding-bottom: 6em;
}

div.hero_bg_txt {
  width: 46%;
  top: 0;
  left: 0;
  position: absolute;
  background: rgb(255, 248, 235);
  background: radial-gradient(
    circle,
    rgba(255, 248, 235, 1) 6%,
    rgba(255, 255, 255, 1) 44%
  );
  min-height: 800px;
}
div.hero_bg_img {
  width: 54%;
  top: 0;
  right: 0;
  position: absolute;
  background: rgb(255, 248, 235);
  background: linear-gradient(
    180deg,
    rgba(255, 248, 235, 1) 6%,
    rgba(255, 242, 219, 1) 55%
  );
  background-repeat: no-repeat;
  min-height: 750px;
}
div.cta_container {
  max-width: 1440px;
  min-height: 750px;
  margin-inline: auto;
  position: relative;
}
section.cta {
  padding-inline: var(--pd-base);
  position: absolute;
  width: 60%;
  left: 0;
  top: 60%;
  transform: translateY(-50%);
}
section.cta h1 {
  font-size: 4rem;
  font-family: var(--ff-accent);
}
section.cta p.slogan {
  font-size: 1rem;
  padding-top: 1em;
  color: #c8ba3f;
  text-align: right;
  padding-right: 100px;
}
section.cta button.btn_cta {
  border: none;
  font-size: 1rem;
  margin-top: 2em;
  padding: 1em 2em;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-light);
  background-color: var(--clr-navy-blue);
}
section.cta button.btn_cta:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
section.cta p.bg_txt {
  color: #f5e9d1;
  font-size: 10rem;
  padding-left: 0.4em;
  letter-spacing: 3rem;
  font-weight: var(--fw-black);
}
section.cta_product {
  right: 0;
  width: 60%;
  height: 100%;
  text-align: right;
  position: absolute;
  padding-inline: var(--pd-base);
}
section.cta_product img.hero_bg {
  width: 700px;
  height: 650px;
  margin-top: 80px;
}

/* featured product  */

div.most_sold_item {
  gap: 1em;
  display: flex;
  bottom: -60px;
  position: absolute;
  right: var(--pd-base);
}
div.most_sold_item figure {
  display: flex;
  padding: 0 1em;
  background: rgb(242, 231, 209);
  background: linear-gradient(
    0deg,
    rgba(242, 231, 209, 1) 53%,
    rgba(255, 248, 235, 1) 85%
  );
  box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
div.most_sold_item figure section {
  text-align: left;
}
div.most_sold_item figure section h2 {
  font-size: 1rem;
  padding-top: 1em;
  font-family: var(--ff-accent);
}
div.most_sold_item figure section p {
  font-size: 0.875rem;
}
div.most_sold_item figure section figcaption {
  color: #c8ba3f;
  padding-top: 1em;
  font-size: 0.8rem;
}
div.most_sold_item figure img {
  width: 120px;
  height: 140px;
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1400px) {
  section.cta p.bg_txt {
    font-size: 8rem;
    padding-left: 0.875em;
  }
  section.cta p.slogan {
    text-align: left;
    padding-left: 22%;
    padding-right: 0px;
  }
  div.most_sold_item figure img {
    height: 110px;
    width: 80px;
    margin-right: -20px;
  }
  div.most_sold_item figure figcaption {
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1300px) {
  section.cta p.bg_txt {
    font-size: 7rem;
    padding-left: 0.5em;
  }
  section.cta_product img.hero_bg {
    width: 600px;
    height: 550px;
    margin-top: 100px;
  }
  section.cta p.slogan {
    padding-left: 25%;
  }
}
@media only screen and (max-width: 1160px) {
  div.cta_container {
    min-height: 600px;
  }
  section.cta h1 {
    font-size: 3rem;
  }
  section.cta p.bg_txt {
    font-size: 7rem;
    padding-left: 0.5em;
  }
  section.cta_product {
    min-height: 750px;
  }
  section.cta_product img.hero_bg {
    width: 500px;
    height: 450px;
    margin-top: 130px;
  }
  section.cta p.slogan {
    padding-left: 20%;
  }
}
@media only screen and (max-width: 1050px) {
  section.cta p.slogan {
    padding-left: 10%;
  }
}
@media only screen and (max-width: 992px) {
  section.cta p.slogan {
    max-width: 470px;
    padding-left: 10%;
  }
}
@media only screen and (max-width: 960px) {
  section.cta_product {
    min-height: auto;
  }
  div.hero_bg_img {
    width: 100%;
    min-height: 400px;
  }
  div.cta_container {
    display: flex;
    min-height: 768px;
    flex-direction: column;
  }
  section.cta {
    order: 2;
    width: 100%;
    position: static;
    padding: 4em 0 0;
    text-align: center;
    transform: translate(0);
  }
  div.hero_bg_txt {
    width: 100%;
    min-height: calc(30vh);
    top: calc(60vh - 105px);
  }
  section.cta h1 {
    max-width: 600px;
    margin-inline: auto;
  }
  section.cta p.slogan {
    padding: 0;
    max-width: 100%;
    text-align: center;
  }
  section.cta p.bg_txt {
    padding: 0;
    font-size: 5rem;
  }
  section.cta_product {
    width: 100%;
    height: calc(60vh - 105px);
    position: relative;
    text-align: center;
  }
  section.cta_product img.hero_bg {
    width: 450px;
    height: 400px;
    margin-top: 50px !important;
  }
  div.most_sold_item {
    right: 0;
    gap: 5em;
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 820px) {
  section.cta_product img.hero_bg {
    margin-top: 100px !important;
  }
  div.hero_bg_img {
    min-height: 600px;
  }
}
@media only screen and (max-width: 768px) {
  div.hero_bg_img {
    min-height: 50vh !important;
  }
}
@media only screen and (max-width: 740px) {
  section.cta_product img.hero_bg {
    margin-top: 40px !important;
  }
  div.hero_bg_img {
    min-height: 400px !important;
  }
}

@media only screen and (max-width: 600px) {
  div.cta_container {
    min-height: auto;
  }
  section.cta h1 {
    max-width: 400px;
    font-size: 2.5rem;
  }
  section.cta p.slogan {
    max-width: 400px;
    margin-inline: auto;
  }
  section.cta button.btn_cta {
    margin-top: 1.5em;
    font-size: 0.875rem;
    padding: 0.8em 1.5em;
  }
  section.cta p.bg_txt {
    font-size: 4rem;
    max-width: 400px;
    text-align: center;
    letter-spacing: 2rem;
    margin-inline: auto;
  }
  section.cta_product {
    max-height: 50vh;
  }
  div.most_sold_item {
    gap: 1em;
  }
  div.most_sold_item figure {
    padding: 0 0.5em;
  }
  div.most_sold_item figure section h4 {
    padding: 0;
  }
  div.most_sold_item figure section p {
    font-size: 0.8rem;
  }
  div.most_sold_item figure section figcaption {
    padding: 0;
  }
  div.most_sold_item figure img {
    height: 80px;
    width: 60px;
  }
}

@media only screen and (max-width: 460px) {
  div.hero {
    padding: 0;
    min-height: 100vh;
  }
  div.hero_bg_img {
    min-height: 50vh !important;
  }

  section.cta_product img.hero_bg {
    width: 300px;
    height: 250px;
    margin-top: 80px !important;
  }
  section.cta h1 {
    max-width: 300px;
    font-size: 1.2rem;
  }
  section.cta p.slogan {
    max-width: 300px;
    font-size: 0.8rem;
  }
  section.cta p.bg_txt {
    max-width: 300px;
    font-size: 2.5rem;
  }
  div.most_sold_item {
    left: 50%;
    gap: 0.5em;
    max-width: 300px;
    transform: translateX(-50%);
  }
  div.most_sold_item figure {
    max-width: 150px;
  }
  div.most_sold_item figure section h4 {
    font-size: 0.875rem;
  }
  div.most_sold_item figure section p,
  div.most_sold_item figure section figcaption {
    font-size: 0.7rem;
  }

  div.most_sold_item figure img {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 380px) {
  section.cta_product {
    padding: 0em 1em;
  }

  section.cta_product img.hero_bg {
    width: 240px;
    height: 200px;
  }
  section.cta p.bg_txt {
    font-size: 2rem;
    max-width: 210px;
    padding-top: 20px;
    letter-spacing: 20px;
  }
  section.cta h1 {
    padding-top: 20px;
  }
  div.most_sold_item figure section h4 {
    font-size: 0.7rem;
  }
  div.most_sold_item figure section p,
  div.most_sold_item figure section figcaption {
    font-size: 0.6rem;
  }

  div.most_sold_item figure img {
    margin-top: 5px;
    margin-right: -10px;
  }
}
@media only screen and (max-width: 300px) {
  section.cta_product img.hero_bg {
    margin-top: 50px !important;
  }
}
@media only screen and (max-height: 1200px) and (min-height: 800px) {
  section.cta_product img.hero_bg {
    margin-top: 100px;
  }
  section.cta h1 {
    padding-top: 20px;
  }
}
@media only screen and (max-height: 460px) {
  div.hero {
    min-height: 750px;
  }
  div.cta_container {
    min-height: 550px;
  }
  section.cta_product {
    min-height: 420px;
  }
}

/* Zenbook  */
@media only screen and (width: 853px) and (height: 1280px) {
  section.cta_product img.hero_bg {
    margin-top: 120px !important;
  }
  div.hero_bg_img {
    min-height: 680px;
  }
}
/* surface pro 7  */
@media only screen and (width: 912px) and (height: 1368px) {
  div.hero {
    min-height: 100vh;
  }
  section.cta_product img.hero_bg {
    width: 650px;
    height: 600px;
    margin-top: 100px !important;
  }
  div.hero_bg_img {
    min-height: 720px;
  }
  section.cta {
    padding: 6em 0 0;
  }
  section.cta h1 {
    padding-top: 20px;
    font-size: 3.8rem !important;
  }
  section.cta button.btn_cta {
    padding: 1em 2em;
    margin-top: 0.5em;
  }
  section.cta p.bg_txt {
    max-width: 550px;
    font-size: 5.5rem;
    margin-inline: auto;
  }
}

/* surface duo  */
@media only screen and (width: 720px) and (height: 1114px) {
  section.cta_product img.hero_bg {
    width: 450px;
    height: 400px;
    margin-top: 100px !important;
  }
  div.hero_bg_img {
    min-height: 50vh !important;
  }
}
@media only screen and (width: 540px) and (height: 720px) {
  div.hero {
    padding: 0;
    min-height: 100vh;
  }
  div.most_sold_item {
    bottom: -120px;
  }
  section.cta h1 {
    padding-top: 60px;
    font-size: 2rem !important;
  }
  section.cta button.btn_cta {
    margin-top: 0.5em;
    padding: 0.8em 1em;
  }
  section.cta p.bg_txt {
    max-width: 360px;
    font-size: 3.5rem;
    margin-inline: auto;
  }
}
@media only screen and (width: 720px) and (height: 540px) {
  div.hero {
    padding: 0;
    min-height: 100vh;
  }
  section.cta_product img.hero_bg {
    width: 250px;
    height: 200px;
  }
  div.hero_bg_img {
    min-height: 50vh !important;
  }
  div.most_sold_item {
    bottom: -100px;
    gap: 1em;
  }
  div.most_sold_item figure section h4 {
    font-size: 0.7rem;
  }
  div.most_sold_item figure section p,
  div.most_sold_item figure section figcaption {
    font-size: 0.6rem;
  }

  div.most_sold_item figure img {
    width: 70px;
    height: 90px;
    margin-top: 5px;
    margin-right: -10px;
  }
  div.cta_container {
    min-height: auto;
  }
  section.cta {
    padding: 6em 0 0;
  }
  section.cta h1 {
    padding-top: 20px;
    font-size: 1.8rem !important;
  }
  section.cta button.btn_cta {
    margin-top: 0.5em;
    padding: 0.8em 1em;
  }
  section.cta p.bg_txt {
    max-width: 450px;
    font-size: 3.5rem;
    margin-inline: auto;
  }
}
