.rating {
  display: flex;
}
.rating img {
  width: 30px;
  height: 30px;
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 992px) {
  .rating img {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .rating img {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 440px) {
  .rating img {
    width: 15px;
    height: 15px;
  }
}
