.account_main {
  min-height: 90vh;
}
.account_menu {
  display: grid;
  margin-inline: auto;
  max-width: var(--wd-base);
  padding-inline: var(--pd-base);
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.account_menu li {
  cursor: pointer;
  text-align: center;
  font-size: var(--fs-normal);
  padding: var(--pd-base-half);
  font-weight: var(--fw-extra-light);
}
.account_menu li.active {
  font-weight: var(--fw-regular);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
  .account_menu li {
    font-size: var(--fs-medium);
  }
}
@media only screen and (max-width: 460px) {
  .account_menu li {
    font-size: var(--fs-small);
  }
}
