div.pageHeader {
  padding-top: 6.5em;
  position: relative;
  background: rgb(255, 249, 236);
  background: linear-gradient(
    176deg,
    rgba(255, 249, 236, 1) 37%,
    rgba(255, 255, 255, 1) 47%
  );
}
h2.pageTitle {
  top: 0;
  left: 50%;
  z-index: 1;
  width: 100%;
  position: absolute;
  text-align: center;
  letter-spacing: 1rem;
  padding-block: 0.5em;
  text-transform: uppercase;
  transform: translate(-50%);
  font-family: var(--ff-accent);
  font-size: calc(var(--fs-x-large) * 1.5);
}
div.pageHeader img {
  right: 0;
  top: 0;
  width: 650px;
  height: 85px;
  object-fit: cover;
  position: absolute;
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 998px) {
  div.pageHeader {
    padding-top: 6em;
    padding-bottom: 0.5em;
  }
  h2.pageTitle {
    font-size: calc(var(--fs-x-large) * 1.2);
  }
  div.pageHeader img {
    width: 350px;
    height: 78px;
  }
}
@media screen and (max-width: 768px) {
  div.pageHeader {
    height: 50px;
    padding-top: 4em;
  }
  h2.pageTitle {
    top: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
    font-size: calc(var(--fs-x-large));
  }
  div.pageHeader img {
    width: 250px;
    height: 100%;
  }
}
@media screen and (max-width: 550px) {
  h2.pageTitle {
    letter-spacing: 0.5rem;
    font-size: calc(var(--fs-large));
  }
  div.pageHeader img {
    width: 130px;
  }
}
@media screen and (max-width: 360px) {
  div.pageHeader {
    height: 50px;
    padding: 0;
  }
  h2.pageTitle {
    letter-spacing: 0.5rem;
    font-size: calc(var(--fs-normal));
  }
  div.pageHeader img {
    width: 120px;
  }
}
@media screen and (max-width: 330px) {
  div.pageHeader {
    height: 48px;
  }
  h2.pageTitle {
    font-size: calc(var(--fs-medium));
  }
  div.pageHeader img {
    height: 48px;
    width: 100px;
  }
}
@media screen and (max-width: 260px) {
  h2.pageTitle {
    letter-spacing: 0.2rem;
  }
}
