.item_container {
  background-size: cover;
  padding: var(--pd-base-1) var(--pd-base-1) 0;
  background-image: url('../../assests/basket_bg.png');
}
.item_header {
  display: grid;
  text-align: center;
  border-bottom: 1px solid #000000;
  grid-template-columns: repeat(5, 1fr);
}
.item_header h3 {
  font-size: var(--fs-large);
  font-weight: var(--fw-extra-light);
}
.empty_basket {
  text-align: center;
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1150px) {
  .item_header h3 {
    font-size: var(--fs-medium);
  }
}
@media only screen and (max-width: 460px) {
  .item_header h3 {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 400px) {
  .item_header h3 {
    font-size: var(--fs-x-small);
  }
}
