.profile_container {
  min-height: 100vh;
  margin-inline: auto;
  background-size: cover;
  max-width: var(--wd-base);
  background-repeat: no-repeat;
  padding-inline: var(--pd-base);
  background-image: url("../../assests/basket_bg.png");
}
.profile_container .profile_info p span:nth-child(1) {
  border-left: 5px solid rgb(228, 236, 9);
  padding-left: 10px;
}
.profile_container section p {
  font-size: var(--fs-medium);
}
.profile_info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-block: 1em;
  place-items: center;
  backdrop-filter: blur(2px);
  justify-content: space-around;
  background-color: rgba(252, 252, 240, 0.5);
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.delete_account {
  border: none;
  padding: 12px;
  position: relative;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-extra-light);
  background-color: var(--clr-red-hightlight2);
}
.delete_account:hover {
  cursor: pointer;
  background-color: var(--clr-red-hightlight3);
}

.billing_address p,
.delivery_address p {
  gap: 3em;
  width: 80%;
  display: grid;
  min-width: 320px;
  margin-inline: auto;
  margin-bottom: 10px;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid rgba(108, 107, 107, 0.1);
}
.address {
  gap: 1em;
  display: flex;
  flex-wrap: wrap;
}
.address > * {
  flex: 1;
}
.address h2 {
  text-align: center;
  margin-bottom: 10px;
  font-size: var(--fs-large);
  font-weight: var(--fw-bold);
}
.address > section {
  padding: 1em;
  margin-top: 1em;
  backdrop-filter: blur(2px);
  background-color: rgba(252, 252, 240, 0.5);
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
button.edit_profile {
  width: 100%;
  border: none;
  padding: 12px;
  position: relative;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  margin-top: var(--mr-base);
  font-weight: var(--fw-extra-light);
  background-color: var(--clr-navy-blue);
}
button.edit_profile span {
  top: 50%;
  margin-left: 10px;
  position: absolute;
  transform: translate(0%, -50%);
}
button.edit_profile:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
@media only screen and (max-width: 768px) {
  .address h2 {
    font-size: var(--fs-medium);
  }
  button.edit_profile {
    padding: 8px;
    font-size: var(--fs-small);
  }
  .delete_account {
    padding: 8px;
    font-size: var(--fs-medium);
  }
}
@media only screen and (max-width: 660px) {
  .address {
    gap: 0em;
    flex-direction: column;
  }
  .billing_address p,
  .delivery_address p {
    width: 100%;
    min-width: 300px;
  }
  .delete_account {
    padding: 6px;
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 560px) {
  .delete_account {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 476px) {
  .profile_info {
    display: grid;
    grid-template-columns: 1fr;
  }

  .delete_account {
    padding: 5px;
    margin-top: 6px;
    font-size: var(--fs-x-small);
  }
}
@media only screen and (max-width: 380px) {
  .billing_address p,
  .delivery_address p {
    min-width: 220px;
  }
  button.edit_profile {
    padding: 5px;
    margin-top: var(--mr-base);
    font-size: var(--fs-x-small);
  }
  .profile_container section p {
    font-size: var(--fs-small);
  }
}
