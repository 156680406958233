.product_container {
  background-color: var(--clr-base);
  background: radial-gradient(
    circle,
    rgba(255, 248, 235, 1) 6%,
    rgba(255, 255, 255, 1) 44%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 9%,
    rgba(255, 248, 235, 0.5) 40%
  );
}
.product_container .product {
  display: grid;
  margin-inline: auto;
  padding: var(--pd-base);
  max-width: var(--wd-base);
  grid-template-columns: 2fr 1fr;
}

.photo {
  height: 500px;
  position: relative;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 9%,
    rgba(255, 248, 235, 1) 40%
  );
  margin-right: 3em;
}
.size_category {
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: top left;
  width: 480px;
  left: 0;
  bottom: -10px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.size_category p {
  text-align: center;
  text-transform: uppercase;
  font-size: var(--fs-medium);
}
.size_category span {
  height: 1px;
  margin-top: 10px;
  background-color: black;
}
.background {
  top: 0;
  right: 0;
  height: 500px;
  position: absolute;
  width: 50%;
  z-index: 1;
}
.background img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}
.foreground {
  position: relative;
  z-index: 2;
  height: 100%;
  margin-inline: auto;
  display: grid;
  place-items: center;
}
.foreground img {
  overflow: hidden;
  aspect-ratio: 1;
  margin-inline: auto;
  object-fit: contain;
  height: 100%;
}

/* **************product details ************* */

.name_price h3 {
  display: flex;
  text-transform: capitalize;
  font-family: var(--ff-accent);
  font-size: var(--fs-xx-large);
  justify-content: space-between;
}
.details p {
  font-size: var(--fs-medium);
}

.input_group {
  padding-top: var(--pd-base);
  font-size: var(--fs-medium);
}
.input_group div {
  padding-block: var(--pd-base-1);
}
.input_group div input,
.input_group div select {
  width: 100%;
  padding: 12px;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 1);
}
.input_group div input:focus,
.input_group div select:focus {
  outline: 1px solid rgb(213, 244, 129);
  border: 1px solid rgb(213, 244, 129);
}
.input_group div input:focus-visible,
.input_group div select:focus-visible {
  border: 1px solid rgb(213, 244, 129);
}
.input_group div.quantity {
  position: relative;
}
.warning {
  bottom: -0.4em;
  color: red;
  position: absolute;
  animation: blink 500ms linear forwards;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0.5;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.details .input_group div button {
  padding: 12px;
  width: 100%;
  margin-top: var(--mr-base);
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-extra-light);
  transition: all 300ms;
  border: none;
  background-color: var(--clr-dark-navy-blue);
}

.details .input_group div button:hover {
  cursor: pointer;
  background-color: var(--clr-navy-blue);
}
.details .input_group div button:disabled {
  cursor: not-allowed;
  background-color: var(--clr-navy-blue);
}
.ingredient {
  text-transform: uppercase;
}
.thumbnail {
  display: flex;
  gap: var(--mr-base);
  padding-top: var(--pd-base-1);
}
.thumbnail div {
  width: 70px;
  height: 70px;
  display: grid;
  place-items: center;
  border: 1px solid black;
  transition: all 300ms;
}
.thumbnail div:hover {
  cursor: pointer;
  background-color: var(--clr-yellow-hightlight);
}
.thumbnail div:nth-child(1) img {
  width: 30px;
  height: 60px;
  object-fit: contain;
}
.thumbnail div:nth-child(2) img {
  width: 50px;
  height: 60px;
  object-fit: contain;
}
/* **************product description ************* */
.description {
  padding-block: calc(var(--pd-base) * 2);
  background-color: var(--clr-dark-navy-blue);
}
.description p {
  max-width: 1440px;
  margin-inline: auto;
  text-align: justify;
  color: var(--clr-white);
  font-size: var(--fs-medium);
  padding-inline: var(--pd-base);
  font-weight: var(--fw-extra-light);
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 992px) {
  .photo {
    height: 400px;
    margin-right: 2em;
  }
  .background {
    height: 400px;
  }
  .size_category {
    width: 380px;
  }

  /* **************product details ************* */

  .name_price h3 {
    font-size: var(--fs-x-large);
  }
  .input_group {
    padding-top: var(--pd-base-point2);
  }
  .input_group div {
    padding-block: var(--pd-base-point2);
  }
  .description {
    padding-block: calc(var(--pd-base) * 1.5);
  }
  .warning {
    bottom: -1.2em;
  }
}

@media only screen and (max-width: 768px) {
  .photo {
    height: 325px;
    margin-right: 1em;
  }
  .background {
    height: 325px;
  }
  .size_category {
    width: 320px;
  }

  .foreground img {
    height: 300px;
    object-fit: contain;
  }

  /* **************product details ************* */

  .name_price h3 {
    font-size: var(--fs-large);
  }
  .details p {
    font-size: var(--fs-small);
  }
  .input_group {
    font-size: var(--fs-small);
  }
  .input_group div input,
  .input_group div select {
    padding: 8px;
  }
  .details .input_group div button {
    padding: 8px;
    font-size: var(--fs-small);
  }
  .thumbnail {
    gap: var(--mr-base-1);
  }
  .thumbnail div {
    width: 50px;
    height: 50px;
  }
  .thumbnail div:nth-child(1) img {
    width: 25px;
    height: 40px;
  }
  .thumbnail div:nth-child(2) img {
    width: 35px;
    height: 40px;
  }
  .description {
    padding-block: calc(var(--pd-base));
  }
}

@media only screen and (max-width: 660px) {
  .product_container .product {
    grid-template-columns: 1fr;
  }
  .photo {
    height: 280px;
    margin-right: 0em;
  }
  .background {
    height: 280px;
  }
  .size_category {
    width: 270px;
  }
  .size_category p {
    font-size: var(--fs-small);
  }
  .size_category span {
    margin-top: 8px;
  }

  .foreground img {
    height: 240px;
  }
  .description p {
    font-size: var(--fs-small);
  }
}

@media only screen and (max-width: 440px) {
  .photo {
    height: 250px;
  }
  .background {
    height: 250px;
  }
  .size_category {
    width: 240px;
  }
  .size_category p {
    font-size: var(--fs-x-small);
  }
  .size_category span {
    margin-top: 7px;
  }
  .foreground img {
    height: 200px;
  }

  /* **************product details ************* */
  .details p {
    font-size: var(--fs-x-small);
  }
  .input_group {
    font-size: var(--fs-x-small);
  }
  .input_group div input,
  .input_group div select {
    padding: 5px;
  }
  .details .input_group div button {
    padding: 5px;
    font-size: var(--fs-x-small);
    margin-top: var(--mr-base);
  }
  .thumbnail div {
    width: 40px;
    height: 40px;
  }
  .thumbnail div:nth-child(1) img {
    width: 20px;
    height: 30px;
  }
  .thumbnail div:nth-child(2) img {
    width: 25px;
    height: 30px;
  }
  .description p {
    font-size: var(--fs-x-small);
  }
}
