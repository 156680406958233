div.new_cart_container h3 {
  font-size: 1.8rem;
  width: max-content;
  text-align: center;
  margin-inline: auto;
  text-transform: uppercase;
  font-family: var(--ff-accent);
  border-image: rgb(255, 255, 255);
  padding-inline: var(--pd-cart-title);
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #ffffff, #fff8eb, #ffffff);
  border-image-slice: 1;
}
div.new_cart {
  margin-top: 5px;
  background-color: var(--clr-base);
  padding-block: calc(var(--pd-base) * 3);
}
section.new_cart_items {
  display: flex;
  gap: 5em;
  flex-wrap: wrap;
  margin-inline: auto;
  max-width: var(--wd-base);
  justify-content: center;
  padding-inline: var(--pd-base);
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 768px) {
  div.new_cart_container h3 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 620px) {
  div.new_cart_container h3 {
    font-size: 1.5rem;
    padding-inline: 5em;
  }
  @media screen and (max-width: 480px) {
    div.new_cart_container h3 {
      font-size: 1.2rem;
      padding-inline: 3em;
    }
    div.new_cart {
      padding-block: calc(var(--pd-base) * 1.5);
    }
  }
  @media screen and (max-width: 400px) {
    div.new_cart_container h3 {
      font-size: 1rem;
      padding-inline: 2em;
    }
    div.new_cart {
      padding-block: calc(var(--pd-base));
    }
    section.new_cart_items {
      gap: 2em;
    }
  }
}
