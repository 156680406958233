.item_to_pay {
  display: grid;
  place-items: center;
  box-shadow: var(--bxsh-df-shadow);
  grid-template-columns: repeat(3, 1fr);
}
.item_to_pay_description {
  display: grid;
  text-align: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

.item_to_pay_description p {
  font-size: 5rem;
  font-weight: 700;
  padding-right: 5px;
  text-align: right;
}
.item_to_pay_description h4 {
  position: absolute;
  grid-column: span 2;
  font-size: var(--fs-small);
  font-weight: var(--fw-bold);
  width: 100%;
  bottom: 10px;
  text-align: center;
  text-transform: uppercase;
}
.item_to_pay_description img {
  height: 60px;
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 500px) {
  .item_to_pay_description p {
    font-size: 3.5rem;
  }
  .item_to_pay_description img {
    height: 45px;
  }
  .item_to_pay_description h4 {
    bottom: 2px;
    font-size: var(--fs-x-small);
  }
  .item_to_pay_size,
  .item_to_pay_price > p {
    font-size: var(--fs-small);
  }
}
