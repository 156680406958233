.purchase_details_container {
  margin-inline: auto;
  max-width: var(--wd-base);
  padding-inline: var(--pd-base);
}
.order_info {
  gap: 1em;
  display: flex;
  flex-wrap: wrap;
  font-size: var(--fs-normal);
  font-weight: var(--fw-bold);
  justify-content: space-around;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.purchase_address {
  gap: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.purchase_billing_address,
.purchase_delivery_address {
  margin-block: 1em;
  padding-block: 1em;
  text-align: center;
  font-size: var(--fs-normal);
  box-shadow: var(--bxsh-df-shadow);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.purchase_items {
  text-align: center;
  font-size: var(--fs-normal);
}
.purchase_item {
  display: grid;
  padding: 0.5em;
  place-items: center;
  font-size: var(--fs-normal);
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 5px solid var(--clr-yellow-hightlight2);
}
.purchase_item img {
  width: 60px;
  height: 60px;
}
.purchase_total {
  margin-top: 10px;
  text-align: center;
  font-size: var(--fs-normal);
  font-weight: var(--fw-bold);
  border-top: 1px solid rgba(0, 0, 0, 1);
}
.btn_see_orders {
  border: none;
  padding: 0.75em;
  font-size: 0.875rem;
  margin: 1em 0em;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-light);
  background-color: var(--clr-navy-blue);
}
.btn_see_orders img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  transform: rotate(-90deg);
}
.btn_see_orders:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 800px) {
  .order_info {
    gap: 0.5em;
    font-size: var(--fs-medium);
  }
  .purchase_address {
    gap: 0.5em;
  }
  .purchase_billing_address,
  .purchase_delivery_address {
    margin-block: 0.5em;
    padding-block: 0.5em;
    font-size: var(--fs-medium);
  }

  .purchase_items,
  .purchase_item,
  .purchase_total {
    font-size: var(--fs-medium);
  }
}

@media only screen and (max-width: 768px) {
  .purchase_details_container {
    padding-top: 1em;
  }
  .btn_see_orders {
    font-size: var(--fs-medium);
  }
}
@media screen and (max-width: 580px) {
  .btn_see_orders {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 460px) {
  .btn_see_orders {
    font-size: var(--fs-x-small);
  }
  .btn_see_orders img {
    width: 10px;
    height: 10px;
  }
  .order_info {
    gap: 0.5em;
    font-size: var(--fs-small);
  }
  .purchase_address {
    gap: 0.5em;
  }
  .purchase_billing_address,
  .purchase_delivery_address {
    margin-block: 0.5em;
    padding-block: 0.5em;
    font-size: var(--fs-small);
  }
  .purchase_items,
  .purchase_item,
  .purchase_total {
    font-size: var(--fs-small);
  }
  .purchase_item img {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 360px) {
  .order_info {
    gap: 0.5em;
    font-size: var(--fs-x-small);
  }
  .purchase_address {
    gap: 0.5em;
  }
  .purchase_billing_address,
  .purchase_delivery_address {
    margin-block: 0.5em;
    padding-block: 0.5em;
    font-size: var(--fs-x-small);
  }

  .purchase_items,
  .purchase_item,
  .purchase_total {
    font-size: var(--fs-x-small);
  }
  .purchase_item img {
    width: 35px;
    height: 35px;
  }
}
