div.promo_cart_container h3 {
  font-size: 1.8rem;
  width: max-content;
  text-align: center;
  margin-inline: auto;
  margin-top: 50px;
  text-transform: uppercase;
  font-family: var(--ff-accent);
  border-image: rgb(255, 255, 255);
  padding-inline: var(--pd-cart-title);
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #ffffff, #fff8eb, #ffffff);
  border-image-slice: 1;
}
div.promo_cart {
  margin-top: 5px;
  padding-top: calc(var(--pd-base) * 2);
}
section.promo_cart_items {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  margin-inline: auto;
  max-width: var(--wd-base);
  justify-content: space-between;
  padding-inline: var(--pd-base);
}
section.promo_cart_items > *:first-child {
  border-left: 25px solid var(--clr-red-hightlight);
  position: relative;
}
section.promo_cart_items > *:last-child {
  border-right: 25px solid var(--clr-red-hightlight);
  position: relative;
}
section.promo_cart_items > *:first-child::before {
  position: absolute;
  content: 'PROMO';
  font-weight: var(--fw-black);
  color: var(--clr-white);
  top: 45%;
  letter-spacing: 1rem;
  left: -85px;
  width: 140px;
  height: 20px;
  transform: rotate(-90deg);
}
section.promo_cart_items > *:last-child::after {
  position: absolute;
  content: 'PROMO';
  font-weight: var(--fw-black);
  color: var(--clr-white);
  top: 50%;
  letter-spacing: 1rem;
  right: -85px;
  width: 140px;
  height: 20px;
  transform: rotate(90deg);
}
.promo_cart .link_more {
  text-align: right;
  max-width: 1440px;
  margin-inline: auto;
  padding: calc(var(--pd-base) * 1.5) var(--pd-base);
}
.link_more a {
  margin-left: auto;
  transition: all 500ms;
  max-width: max-content;
  font-size: var(--fs-x-large);
  font-weight: var(--fw-normal);
  color: var(--clr-yellow-hightlight);
}
.link_more a:hover {
  cursor: pointer;
  color: var(--clr-red-hightlight);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 1400px) {
  section.promo_cart_items {
    gap: 1.5em;
    justify-content: center;
    padding-inline: 1em;
  }
  .promo_cart .link_more {
    max-width: 1100px;
  }
}
@media screen and (max-width: 990px) {
  section.promo_cart_items {
    justify-content: space-around;
  }
}
@media screen and (max-width: 768px) {
  div.promo_cart_container h3 {
    font-size: 1.5rem;
    padding-inline: calc(var(--pd-cart-title) / 2);
  }
}
@media screen and (max-width: 620px) {
  div.promo_cart_container h3 {
    margin-top: 20px;
    padding-inline: calc(var(--pd-cart-title) / 3);
  }
  div.promo_cart {
    margin-top: 5px;
    padding-top: calc(var(--pd-base));
  }
  section.promo_cart_items > *:first-child::before {
    font-size: var(--fs-medium);
    top: 40%;
    left: -82px;
  }
  section.promo_cart_items > *:last-child::after {
    font-size: var(--fs-medium);
    top: 50%;
    right: -82px;
  }
}
@media screen and (max-width: 480px) {
  div.promo_cart_container h3 {
    font-size: 1.2rem;
    padding-inline: 3em;
  }
  .promo_cart .link_more {
    text-align: center;
    padding: calc(var(--pd-base));
  }
  .link_more a {
    font-size: var(--fs-medium);
  }
}
@media screen and (max-width: 400px) {
  div.promo_cart_container h3 {
    font-size: 1rem;
    padding-inline: 2em;
  }

  section.promo_cart_items {
    gap: 1.5em;
  }

  section.promo_cart_items > *:first-child {
    border-left-width: 15px;
  }
  section.promo_cart_items > *:last-child {
    border-right-width: 15px;
  }
  section.promo_cart_items > *:first-child::before {
    font-size: var(--fs-small);
    top: 38%;
    left: -77px;
    width: 140px;
    height: 20px;
  }
  section.promo_cart_items > *:last-child::after {
    font-size: var(--fs-small);
    top: 52%;
    right: -77px;
  }
}
