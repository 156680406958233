.edit_profile_container {
  min-height: 100vh;
  margin-inline: auto;
  background-size: cover;
  max-width: var(--wd-base);
  background-repeat: no-repeat;
  padding-inline: var(--pd-base);
  background-image: url('../../assests/basket_bg.png');
}
.edit_profile_info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-block: 1em;
  backdrop-filter: blur(2px);
  justify-content: space-around;
  background-color: rgba(252, 252, 240, 0.5);
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.edit_address {
  gap: 1em;
  display: flex;
  flex-wrap: wrap;
}
.edit_address > * {
  flex: 1;
}
.edit_address h2 {
  text-align: center;
  font-size: var(--fs-large);
  font-weight: var(--fw-bold);
}
.edit_address > section {
  padding: 1em;
  margin-top: 1em;
  backdrop-filter: blur(2px);
  background-color: rgba(252, 252, 240, 0.5);
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}
.country_default_section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-size: var(--fs-medium);
}
.country_default_section p:nth-child(1) {
  display: flex;
  justify-content: space-between;
}
.country_default_section p:nth-child(1) span:nth-child(2){
  margin-right: 3px;
}
.country_default_section p:nth-child(2) {
  margin-left: 10px;
}
.country_warning {
  padding-top:10px;
  text-align: center;
  font-size: var(--fs-medium);
  color: var( --clr-red-hightlight);
}
button.edit_profile_submit {
  width: 100%;
  border: none;
  padding: 12px;
  position: relative;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  margin-block: var(--mr-base);
  font-weight: var(--fw-extra-light);
  background-color: var(--clr-navy-blue);
}
button.edit_profile_submit span {
  top: 50%;
  margin-left: 10px;
  position: absolute;
  transform: translate(0%, -50%);
}
button.edit_profile_submit:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
button.edit_profile_submit:disabled {
  cursor: not-allowed;
  background-color: var(--clr-navy-blue);
}
@media only screen and (max-width: 768px) {
  .edit_address h2 {
    font-size: var(--fs-medium);
  }
  button.edit_profile_submit {
    padding: 8px;
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 660px) {
  .edit_address {
    gap: 0;
  }
  .country_warning {
    font-size: var(--fs-small);
  }
}
@media only screen and (max-width: 360px) {
  button.edit_profile_submit {
    padding: 5px;
    font-size: var(--fs-x-small);
    margin-top: var(--mr-base);
  }
}
