.error_container {
  height: 67vh;
  display: grid;
  place-items: center;
}
.error_container h2 {
  color: red;
  text-align: center;
  font-size: var(--fs-large);
  font-weight: var(--fw-light);
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 768px) {
  .error_container h2 {
    font-size: var(--fs-normal);
  }
}

@media only screen and (max-width: 460px) {
  .error_container h2 {
    font-size: var(--fs-small);
  }
}
