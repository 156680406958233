.order_tbl {
  text-align: center;
  max-width: var(--wd-base);
  font-size: var(--fs-normal);
  margin: var(--mr-base-1) auto;
  background-color: var(--clr-base);
}
.order_body_row {
  transition: all 400ms;
  padding-inline: var(--pd-base);
  padding-block: var(--pd-base * 3);
}
.order_body_row:nth-child(odd) {
  background-color: rgba(165, 74, 239, 0.1);
}
.order_body_row:hover {
  cursor: pointer;
  background-color: var(--clr-yellow-hightlight);
}
section.btn_container {
  text-align: center;
}
section.btn_container button {
  border: none;
  font-size: 1rem;
  padding: 1em 2em;
  margin-block: 1em;
  transition: all 300ms;
  color: var(--clr-white);
  text-transform: uppercase;
  font-weight: var(--fw-light);
  background-color: var(--clr-navy-blue);
}
section.btn_container button:hover {
  cursor: pointer;
  background-color: var(--clr-dark-navy-blue);
}
/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */
@media screen and (max-width: 998px) {
  section.btn_container button {
    font-size: 0.875rem;
    padding: 0.8em 1em;
    font-size: var(--fs-medium);
  }
}

@media only screen and (max-width: 768px) {
  .order_tbl {
    font-size: var(--fs-medium);
  }
}
@media only screen and (max-width: 640px) {
  .order_tbl {
    padding-inline: var(--pd-base);
  }
  section.btn_container button {
    font-size: var(--fs-small);
  }
}

@media only screen and (max-width: 400px) {
  .order_tbl {
    font-size: var(--fs-small);
  }
  section.btn_container button {
    font-size: var(--fs-x-small);
  }
}
