._input_group {
  gap: 10px;
  display: grid;
  position: relative;
  margin-block: 10px;
  margin-bottom: 20px;
  font-size: var(--fs-medium);
  grid-template-columns: 1fr 2fr;
  font-weight: var(--fw-extra-light);
}
._input_group input {
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid #000000;
}
._input_group input:focus {
  box-shadow: none;
  outline-style: none;
  border-bottom: 1px solid #000000;
}
._input_group label {
  gap: 5px;
  display: flex;
  justify-content: space-between;
}
._input_group p {
  color: red;
  bottom: -16px;
  position: absolute;
  font-weight: var(--fw-bold);
  font-size: var(--fs-x-small);
  animation: blink 700ms linear forwards;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0.5;
  }
  65% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 360px) {
  ._input_group {
    margin-block: 5px;
    margin-bottom: 20px;
    font-size: var(--fs-small);
  }
}
