.basket_section {
  gap: 20px;
  display: grid;
  min-height: 100vh;
  margin-inline: auto;
  max-width: var(--wd-base);
  grid-template-columns: 2fr 1fr;
  padding-inline: var(--pd-base);
}
.empty_basket {
  display: grid;
  min-height: 100vh;
  place-items: center;
}

/* ************* **********  ****************
**************** responsive  ****************
**************** **********  ************* */

@media only screen and (max-width: 1150px) {
  .basket_section {
    gap: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .basket_section {
    gap: 10px;
    grid-template-columns: 1fr;
  }
}
