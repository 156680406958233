div.no_product_container {
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: rgb(255, 248, 235);
  background: radial-gradient(at center, #ffffff, #fff8eb);
}
div.no_product_container h3 {
  font-weight: var(--fw-light);
  color: var(--clr-red-hightlight);
}
/* ********************************* */
/* *********media queries*********** */
/* ********************************* */

@media screen and (max-width: 550px) {
  div.no_product_container h3 {
    font-size: var(--fs-medium);
  }
}
