:root {
  /* font-family */
  --ff-base: "Poppins", sans-serif;
  --ff-accent: "DM Serif Display", serif;

  /* font-weight */
  --fw-extra-light: 300;
  --fw-light: 400;
  --fw-regular: 500;
  --fw-bold: 600;
  --fw-extra-bold: 700;
  --fw-black: 800;
  --fw-extra-black: 900;

  /* font size */
  --fs-xx-large: 2rem;
  --fs-x-large: 1.5rem;
  --fs-large: 1.25rem;
  --fs-normal: 1rem;
  --fs-medium: 0.875rem;
  --fs-small: 0.75rem;
  --fs-x-small: 0.625rem;
  --fs-xx-small: 0.5rem;

  /* color */
  --clr-white: #ffffff;
  --clr-black: #000000;
  --clr-base: #fff8eb;
  --clr-navy-blue: #002642;
  --clr-dark-navy-blue: #050a24;
  --clr-gold-black: #181010;
  --clr-red-hightlight: #ff0000;
  --clr-red-hightlight2: #dc0a0a;
  --clr-red-hightlight3: #aa0404;
  --clr-yellow-hightlight: #f0d01a;
  --clr-yellow-hightlight2: #d5b817;
  --clr-red-hightlight-light: #ff671b;

  /* padding */
  --pd-base: 1.5em;
  --pd-base-1: 1em;
  --pd-base-half: 0.5em;
  --pd-base-point2: 0.2em;

  --pd-cart-title: 10em;

  /* margin */
  --mr-base: 1.5em;
  --mr-base-1: 1em;
  --mr-base-half: 0.5em;
  --mr-cart-title: 10em;

  /* width */
  --wd-base: 1440px;

  /* default box shadow */
  --bxsh-df-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1),
    0 4px 4px 0 rgba(0, 0, 0, 0.15);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* WebKit Browsers (Chrome, Safari) */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  border-radius: 0;
  -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Reset to the desired background color, e.g., white */
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s; /* Prevent flashing background color */
}

/* Optional: Adjust for dark mode */
@media (prefers-color-scheme: dark) {
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    border-radius: 0;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Use a dark background color */
    -webkit-text-fill-color: #ffffff !important; /* Set text color for dark mode */
  }
}

/* Mozilla Firefox */
input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset !important; /* Similar approach for Firefox */
  color: #ffffff !important; /* Set text color */
  background: rgba(0, 0, 0, 0) !important; /* Ensure the background is set */
}

li {
  list-style: none;
}
a:not(.link_balhowaimil) {
  text-decoration: none;
  color: var(--clr-black);
}
body {
  font-family: "Poppins", sans-serif;
}
